<template>
<div>
<AddRSSFeedModalV2></AddRSSFeedModalV2>
    <div class="pb-6 pt-[84px] px-6 w-full h-screen bg-[#F7F7FC]">
      <div class="grid_box_shadow bg-white w-full h-full bg-opacity-80 rounded-lg">
        <div class="flex flex-col justify-between h-full">
          <div :class="{'border-b': getRSS.total_items > getRSS.limit}" class="flex flex-col flex-grow h-full border-[#ECEEF5]">
            <RSSFiltersV2></RSSFiltersV2>
            <RSSFeedsTableV2></RSSFeedsTableV2>
          </div>
          <RSSPaginationV2></RSSPaginationV2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default ({
  'name': 'RSSListingV2',
  components: {
    RSSFeedsTableV2: () => import('@/views/pages/rss-feeds/dialogs/RSSFeedsTableV2.vue'),
    RSSFiltersV2: () => import('@/views/pages/rss-feeds/dialogs/RSSFiltersV2.vue'),
    AddRSSFeedModalV2: () => import ('@/views/pages/rss-feeds/dialogs/AddRSSFeedModalV2.vue'),
    RSSPaginationV2: () => import ('@/views/pages/rss-feeds/dialogs/RSSPaginationV2.vue')
  },
  computed: {
    ...mapGetters(['getRSS'])
  },
  methods: {
    ...mapActions(['fetchCampaignsList'])
  }
})
</script>
